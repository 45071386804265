import React, { Component } from "react";
import "./footer.css";

class Footer extends Component {

    render() {
        return (
            <footer>
                <div className="footer-content">
                    <section className="footer-base">
                        <div className="footer-legal">
                            <div className="footer-legal-copyright">Copyright © 2024 Luca Valerie Trautmann All rights reserved.</div>
                            <div className="footer-legal-links">
                                <div className="">

                                    <a href="https://www.linkedin.com/in/luca-trautmann-51a90a203/" target="_blank">
                                        <img src={"/application_icons/linkedin.png"} style={{ filter: 'invert(100%)' }} alt="LinkedIn" className="socialiconsfooter"></img>
                                    </a>
                                    <a href="https://github.com/LucaTrautmann" target="_blank">
                                        <img src={"/application_icons/github.png"} style={{ filter: 'invert(100%)' }} alt="github" className="socialiconsfooter"></img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </footer>
        )
    }
}

export default Footer;
