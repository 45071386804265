import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import DrawerAppBar from "./components/widgets/sidebar";
import Footer from "./components/widgets/footer";
import "./index.css";
import { projectList } from "./components/pages/projects/project_list";
import Loading from "./components/widgets/loading";


const AboutMe = React.lazy(() => import("./components/pages/about/aboutme"));
const LandingPage = React.lazy(() => import("./components/pages/landing/landing_page"));
const Photography = React.lazy(() => import("./components/pages/photography/photography"));
const Projects = React.lazy(() => import("./components/pages/projects/projects"));
const ProjectDetail = React.lazy(() => import("./components/pages/projects/project_detail"));

function App() {
  return (
    <Router>
      <DrawerAppBar></DrawerAppBar>
      <div style={{ padding: "66px 0 0" }}>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route path="/aboutme" element={<AboutMe />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/photography" element={<Photography />} />
            <Route path="/project_detail/:index" element={<ProjectDetail projectList={projectList} />} />
          </Routes>
        </Suspense>
        </div>
      <Footer></Footer>
    </Router>
  );
}

export default App;
