import './loading.css'

const Loading = () => {
  
    return (
        <div className='spacing'>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
        
    );
  };
  
  export default Loading;
  