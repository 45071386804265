import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

const drawerWidth = 300;


const styles = {
    appBar: {
        position: "fixed",
        top: 0,
        height: "66px",
        backgroundColor: "transparent",
        backdropFilter: "blur(5px)",
        boxShadow: "none",
        display: "flex",
        justifyContent: "center", // Zentriert den Inhalt horizontal
        alignItems: "center",

        padding: "0 5vh",
        margin: "0px",
    },

    paper: {
        backgroundColor: "#1a1a1a",
        color: "aliceblue",
    },
    toolbar: {
        padding: "0",
        display: "flex",
        backgroundColor: "transparent",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        // Füllt den verfügbaren Platz innerhalb der AppBar aus
    },
    link: {
        color: "white",
        textDecoration: "none",
        fontWeight: 200,
        fontSize: "15px",
        margin: "15px"
    },
    titleName: {
        color: "white",
        textDecoration: "none",
        fontWeight: 900,
        fontSize: "25px",
        width: "50%",
        margin: "0"
    },
    titleLinks: {
        width: "50%",
        display: "flex", // Damit die flexDirection-Eigenschaften wirken, sollte display auf "flex" gesetzt werden
        justifyContent: "flex-end", // Um Elemente vertikal unten auszurichten
        margin: "0",
        padding: "0"
    }

    

};





function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth Scroll nach oben
}


function DrawerAppBar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'start', paddingLeft:"20px" }}>
            <Typography sx={{ my: 2, fontWeight: 900, fontSize:"1.5rem"}} >
                Luca Trautmann
            </Typography>
            <Divider />
            <List>
                
                    <ListItem disablePadding>
                        <ListItemButton to='/' component={Link} sx={{ textAlign: 'start'}} onClick={scrollToTop}>
                            <ListItemText>Home</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton to='/aboutme' component={Link} sx={{ textAlign: 'start'}} onClick={scrollToTop}>
                            <ListItemText>About me</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton to='/projects' component={Link} sx={{ textAlign: 'start'}} onClick={scrollToTop}>
                            <ListItemText>Projects</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton to='/photography' component={Link} sx={{ textAlign: 'start'}} onClick={scrollToTop}>
                            <ListItemText>Photography</ListItemText>
                        </ListItemButton>
                    </ListItem>
                
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" style={styles.appBar}>
                <Toolbar style={styles.toolbar}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, fontWeight: 900, display: { xs: 'none', sm: 'block' } }}
                    >
                        Luca Trautmann
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>

                        <Button to='/' component={Link} sx={{
                                color: 'white',
                                textDecoration: 'none',
                                fontWeight: 200,
                                fontSize: '15px',
                                margin: '15px',
                                textTransform: 'none',
                                '&:hover': {
                                    fontWeight: 900, // Hinzufügen einer unterstrichenen Dekoration bei Hover
                                },
                            }}
                            onClick={scrollToTop}>
                            Home
                        </Button>
                        <Button to='/aboutme' component={Link} sx={{
                                color: 'white',
                                textDecoration: 'none',
                                fontWeight: 200,
                                fontSize: '15px',
                                margin: '15px',
                                textTransform: 'none',
                                '&:hover': {
                                    fontWeight: 900, // Hinzufügen einer unterstrichenen Dekoration bei Hover
                                },
                            }}
                            onClick={scrollToTop}>
                            About me
                        </Button>
                        <Button to='/projects' component={Link} sx={{
                                color: 'white',
                                textDecoration: 'none',
                                fontWeight: 200,
                                fontSize: '15px',
                                margin: '15px',
                                textTransform: 'none',
                                '&:hover': {
                                    fontWeight: 900, // Hinzufügen einer unterstrichenen Dekoration bei Hover
                                },
                            }}
                            onClick={scrollToTop}>
                            Projects
                        </Button>
                        <Button to='/photography' component={Link} sx={{
                                color: 'white',
                                textDecoration: 'none',
                                fontWeight: 200,
                                fontSize: '15px',
                                margin: '15px',
                                textTransform: 'none',
                                '&:hover': {
                                    fontWeight: 900, // Hinzufügen einer unterstrichenen Dekoration bei Hover
                                },
                            }}
                            onClick={scrollToTop}>
                            Photography
                        </Button>

                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { 
                          ...styles.paper,
                          boxSizing: 'border-box',
                          width: drawerWidth,
                        },
                      }}
                >
                    {drawer}
                </Drawer>
            </nav>

        </Box>
    );
}

DrawerAppBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default DrawerAppBar;
